<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>
				
				<a-row v-show="showAll">
					<a-form-item label="会员昵称" name="memberName" class="ui-form__item">
						<a-input v-model:value="formState.memberName" placeholder="请输入会员昵称"></a-input>
					</a-form-item>
					
					<a-form-item label="会员手机" name="memberPhone" class="ui-form__item">
						<a-input v-model:value="formState.memberPhone" placeholder="请输入会员手机"></a-input>
					</a-form-item>
					
					<a-form-item label="卖品券号" name="barcode" class="ui-form__item">
						<a-input v-model:value="formState.barcode" placeholder="请输入兑换券号"></a-input>
					</a-form-item>
					
					<a-form-item label="批次号" name="batchNo" class="ui-form__item">
						<a-input v-model:value="formState.batchNo" placeholder="请输入券批次"></a-input>
					</a-form-item>

					<a-form-item label="批次名称" name="name" class="ui-form__item">
						<a-input v-model:value="formState.name" placeholder="请输入券批次名称"></a-input>
					</a-form-item>
					
					<a-form-item label="券状态" name="status" class="ui-form__item">
						<a-select v-model:value="formState.status" style="width: 180px;" placeholder="请选择券状态">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">正常</a-select-option>
							<a-select-option :value="2">已兑换</a-select-option>
							<a-select-option :value="3">已过期</a-select-option>
							<!-- <a-select-option :value="4">未激活</a-select-option> -->
						</a-select>
					</a-form-item>

					<a-form-item label="是否启用" name="isDisabled" class="ui-form__item">
						<a-select v-model:value="formState.isDisabled" style="width: 180px;" placeholder="请选择是否启用">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">已禁用</a-select-option>
							<a-select-option :value="0">已启用</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="有效期开始时间" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>

					<a-form-item label="有效期结束时间" class="ui-form__item">
						<a-range-picker v-model:value="time1"></a-range-picker>
					</a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
						<a-button :disabled="selectedRowKeys.length === 0" v-permission="['coupon_snack_info_delays']" type="primary" style="margin-right: 20px;" @click="onBatchDelay">批量延期</a-button>
						<a-button :disabled="selectedRowKeys.length === 0" v-permission="['coupon_snack_info_unbind']" type="primary" style="margin-right: 20px;" @click="onShowTaskModal('unbundlingSaleCoupon')">批量解绑</a-button>
						<span v-permission="['coupon_snack_info_export']">
							<!-- 导出 -->
							<exportComponent type="userSnackCoupon" :searchData="searchData"></exportComponent>
						</span>
						
						<a style="margin-left: 20px;" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>			
			
			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id" :rowSelection="{ type: 'checkbox', selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll, getCheckboxProps: getCheckboxProps }" :scroll="{ x: 1800 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'userInfo'">
							<div>会员昵称：{{ record.memberName || '-' }}</div>
							<div>会员手机：{{ record.memberPhone || '-' }}</div>
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.startTime, 1) }} - {{ transDateTime(record.endTime, 1) }}
						</template>
						<template v-if="column.key === 'status'">
							{{ ['正常', '已兑换', '已过期', '未激活'][record.status - 1] }}
						</template>
						<template v-if="column.key === 'isHoliday'">
							<div v-if="record.isHoliday">通兑</div>
							<div v-else>非通兑</div>
						</template>
						<template v-if="column.key === 'isGrant'">
							{{ record.isGrant ? '是' : '否' }}
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled ? '已禁用' : '已启用' }}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['coupon_snack_info_unbind']" v-if="!record.isGrant" @click="onUnBind(record)">
											<a-menu-item>
												解绑
											</a-menu-item>
										</div>
										<div v-permission="['coupon_snack_info_delay']" @click="onDelay(record)">
											<a-menu-item>
												延期
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
		
		<a-modal v-model:visible="visible" title="已选" width="850px" destroyOnClose @ok="onBatchDelayOk" @cancel="onBatchCancel">
			<a-spin :spinning="loading">
			<a-button style="margin-bottom: 20px;" @click="onDelay(null)">设置延期时间</a-button>
			
			<a-table :dataSource="selectedListData" :columns="selectedColumns" :pagination="false">
				<template #bodyCell="{ column, record }">
					<template v-if="column.key === 'endTime'">
						{{ transDateTime(record.endTime, 1) }}
					</template>
					<template v-else-if="column.key === 'newEndTime'">
						<span v-if="delayModelRef.setValue && !setVisible">{{ transDateTime(delayEndTime(record.endTime), 1) }}</span>
					</template>
				</template>
			</a-table>
			</a-spin>
		</a-modal>
		
		<a-modal v-model:visible="setVisible" width="800px" destroyOnClose title="卖品券延期" @ok="onDelayOk" @cancel="onDelayCancel">
			<a-spin :spinning="loading">
			<a-form  ref="delayForm" name="delayForm" :model="delayModelRef" :labelCol="{span: 5}" :wrapperCol="{span: 16}">
				<div v-if="!visible">
					<a-form-item label="会员账号">{{ setRecordData.memberPhone }}</a-form-item>
					<a-form-item label="卖品券号">{{ setRecordData.barcode }}</a-form-item>
					<a-form-item label="有效时间">{{ transDateTime(setRecordData.endTime, 1) }}</a-form-item>
				</div>
				
				<a-form-item label="快捷选择" name="setValue" :rules="[{required: true, message: '必选项不允许为空'}]">
					<a-radio-group v-model:value="delayModelRef.setValue" button-style="solid">
						<a-radio-button :value="1">1天</a-radio-button>
						<a-radio-button :value="2">7天</a-radio-button>
						<a-radio-button :value="3">3月</a-radio-button>
						<a-radio-button :value="4">6月</a-radio-button>
						<a-radio-button :value="5">1年</a-radio-button>
						<a-radio-button :value="6">自定义日期</a-radio-button>
						<a-radio-button :value="7">自定义时间</a-radio-button>
					</a-radio-group>
				</a-form-item>
				
				<div>
					<a-form-item v-if="delayModelRef.setValue === 6" label="延期至" name="specifiesTime" :rules="[{required: true, message: '必选项不允许为空'}]">
						<a-date-picker :disabled-date="disabledDate" v-model:value="delayModelRef.specifiesTime" />
						<p style="margin-top: 6px;color: #666;">需大于今天才会生效</p>
					</a-form-item>
					<a-form-item v-else-if="delayModelRef.setValue === 7" label="延期至" name="delayDays" :rules="[{required: true, message: '必选项不允许为空'}]">
						<a-input-number v-model:value="delayModelRef.delayDays" :min="0" :precision="0" placeholder="请输入"></a-input-number>
						<span style="padding-left: 8px;">天</span>
					</a-form-item>
					<a-form-item v-else-if="!visible" label="延期至">
						<span>{{ transDateTime(delayEndTime(setRecordData.endTime), 1) }}</span>
					</a-form-item>
				</div>
			</a-form>
			</a-spin>
		</a-modal>
		
		<a-modal v-model:visible="taskVisible" title="创建批量操作任务" @ok="onCreateTaskOk" destroyOnClose>
			<a-spin :spinning="loading">
				<a-form>
					<a-form-item label="任务名称">
						<a-input v-model:value="taskTitle" placeholder="请输入"/>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import exportComponent from '@/components/exportReport/exportReport.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getExchangeInfoList, unbundlingExchangeInfo, delayExchange, batchDelayExchangeAsync, batchUnbundling, asyncBatchUnbundling } from '@/service/modules/coupon.js';
	export default {
		components: {Icon, exportComponent },
		data() {
			return {
				loading: false,
				showAll: false,
				organizationList: [],
				cinemaAllList: [],
				formState: {
					couponType: 3,
					// organizationId: 0,
					cinemaId: 0,
					status: 0,
					isDisabled: ''
				},
				time: [],
				time1: [],
				searchData: {},
				showModal: false,
				modelRef: {},
				setValue: undefined,
				visible: false,
				setVisible: false,
				setRecordData: {},
				delayModelRef: {},
				selectedRowKeys: [],
				selectedListData: [],
				selectedColumns: [{
					title: '影院',
					dataIndex: 'cinemaName'
				}, {
					title: '卖品券号',
					dataIndex: 'barcode'
				}, {
					title: '延期前有效期',
					key: 'endTime'
				}, {
					title: '延期后有效期',
					key: 'newEndTime'
				}],
				list: [],
				columns: [{
					title: '影院名称',
					dataIndex: 'cinemaName'
				}, {
					title: '批号',
					dataIndex: 'batchNo'
				},{
					title: '会员信息',
					key: 'userInfo'
				}, {
					title: '卖品券号',
					dataIndex: 'barcode'
				}, {
					title: '有效期',
					key: 'time',
					width: 200
				},{
					title: '使用状态',
					width: 150,
					key: 'status'
				}, {
					title: '是否商品卖品发放',
					key: 'isGrant'
				}, {
					title: '是否启用',
					key: 'isDisabled',
					width: 150
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 150
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					// pageSizeOptions: ['10','20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				taskVisible: false,
				taskTitle: '',
				taskType: ''
			}
		},
		created() {
			this.getOrganizationList();
			//this.onSearch();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.selectedListData = [];
				this.selectedRowKeys = [];
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.isDisabled = this.searchData.isDisabled !== '' ? this.searchData.isDisabled : undefined;
				if (this.time && this.time.length) {
					this.searchData.validStartTime = this.time[0].startOf('day').unix();
					this.searchData.validEndTime = this.time[1].endOf('day').unix();
				}
				if (this.time1 && this.time1.length) {
					this.searchData.failureStartTime = this.time1[0].startOf('day').unix();
					this.searchData.failureEndTime = this.time1[1].endOf('day').unix();
				}
				if(!this.searchData.status) {
					delete this.searchData.status;
				} 
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.time = [];
				this.time1 = [];
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getExchangeInfoList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						couponType: 3,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			delayEndTime(expireDate) {
				//  延期时间处理
				if(!expireDate) return '';
				if ((expireDate+'').length === 10) {
					expireDate = expireDate * 1000;
				}
				if (this.delayModelRef.setValue === 1) {
					return this.moment(expireDate).add(1, 'day').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 2) {
					return this.moment(expireDate).add(7, 'day').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 3) {
					return this.moment(expireDate).add(3, 'month').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 4) {
					return this.moment(expireDate).add(6, 'month').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 5) {
					return this.moment(expireDate).add(12, 'month').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 6) {
					return this.delayModelRef.specifiesTime.endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 7) {
					if (this.delayModelRef.delayDays) {
						return this.moment(expireDate).add(this.delayModelRef.delayDays, 'day').endOf('day').valueOf();
					} else {
						return this.moment(expireDate).endOf('day').valueOf();
					}
				} else {
					return this.moment(expireDate).endOf('day').valueOf();
				}
			},
			onDelay(item) {
				if (item) {
					this.selectedRowKeys = [];
					this.selectedListData = [];
				}
				this.setRecordData = item;
				this.setVisible = !this.setVisible;
			},
			onBatchUnbundling() {
				this.$confirm({
					title: '提示',
					content: '确定要批量解绑已选择的券吗？',
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await asyncBatchUnbundling({
								ids: this.selectedRowKeys.join(','),
								type: this.taskType,
								title: this.taskTitle
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('任务创建成功，请在任务中心-任务列表查看完成情况')
								this.selectedListData = [];
								this.selectedRowKeys = [];
								this.taskVisible = false;
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onBatchDelay() {
				this.visible = true;
			},
			onDelayCancel() {
				if(!this.visible) {
					this.delayModelRef = {};
				}
				this.setVisible = false;
			},
			onDelayOk() {
				// 延期
				this.$refs.delayForm.validateFields().then(async () => {
					if (!this.visible) {
						this.loading = true;
						try {
							let ret = await delayExchange({
								id: this.setRecordData.id,
								delayTime: parseInt(this.delayEndTime(this.setRecordData.endTime) / 1000)
							});
							this.loading = false;
							if (ret.code === 200) {
								this.setRecordData = {};
								this.$message.success('操作成功');
								this.setVisible = false;
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					} else {
						this.setVisible = false;
					}
				})
			},
			onBatchCancel() {
				this.delayModelRef = {};
				this.visible = false;
			},
			async onBatchDelayOk() {
				// 批量延期
				if (!this.delayModelRef.setValue) {
					return this.$message.warn('请设置延期时间');
				} else {
					this.taskTitle = '';
					this.taskType = '';
					this.taskVisible = true;
				}
			},
			async onAsyncBatchOk() {
				let postData = this.selectedListData.map(item => {
					return {
						id: item.id,
						delayTime: parseInt(this.delayEndTime(item.endTime) / 1000)
					}
				})
				this.loading = true;
				try {
					let ret = await batchDelayExchangeAsync({
						title: this.taskTitle,
						couponType: 3,
						userCoupons: postData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.delayModelRef = {};
						this.selectedRowKeys = [];
						this.selectedListData = [];
						this.$message.success('操作成功');
						this.visible = false;
						this.taskVisible = false;
						this.getData();
					}
				} catch(e) {
					console.log(e)
					this.loading = false;
				}
			},
			onSelectChange(record, selected) {
				if (selected) {
					if (this.selectedRowKeys.length < 500) {
						this.selectedListData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.$message.warn('最大可选择数据不允许大于500条');
					}
				} else {
					this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
					this.selectedListData = this.selectedListData.filter(item => {
						return item.id !== record.id;
					});
				}
			},
			onSelectAll(selected) {
				if (selected) {
					let showWain = true;
					this.list.forEach(item => {
						if (this.selectedRowKeys.length < 500) {
							if (this.selectedRowKeys.indexOf(item.id) === -1 && item.status !== 2) {
								this.selectedListData.push(JSON.parse(JSON.stringify(item)));
								this.selectedRowKeys.push(item.id);
							}
						} else {
							if (showWain) {
								showWain = false;
								this.$message.warn('最大可选择数据不允许大于500条');
							}
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			getCheckboxProps(record) {
				return {
					disabled: record.status === 2,
					id: record.id.toString()
				}
			},
			onUnBind(item) {
				this.$confirm({
					title: '提示',
					content:'确定解绑吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await unbundlingExchangeInfo({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onDetail() {
				this.showModal = !this.showModal
			},
			disabledDate(current) {
				return current && current < this.moment().endOf('day');
			},
			onShowTaskModal(type) {
				let canBatchBind = true;
				for (let i = 0; i < this.selectedListData.length;i++) {
					if (this.selectedListData[i].isGrant) {
						canBatchBind = false;
						break;
					}
				}
				if (!canBatchBind) {
					return this.$message.warn('已选择的兑换券中含有【用于商品卖品发放】的兑换券，无法进行批量解绑操作');
				}
				this.taskType = type;
				this.taskTitle = '';
				this.taskVisible = true;
			},
			onCreateTaskOk() {
				if (!this.taskTitle.trim()) {
					this.$message.warn('请输入任务名称');
				    return;
				}
				if (this.taskType === 'unbundlingSaleCoupon') {
					this.onBatchUnbundling();
				}
				if (this.taskType === '') {
					this.onAsyncBatchOk();
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
